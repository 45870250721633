<template>
    <b-container fluid class="medium black mt-2">
        <b-row class="justify-content-start" align-v="center" align-h="start">
            <b-col>
                <div class="text-danger font-weight-bold">
                    <i class="fas fa-notes-medical"></i> Change in Condition
                </div>
                <div class="text-danger font-weight-bold">
                    {{ assessmentName }} ({{ isActive }})
                </div>
                <div>
                    <span class="small">{{ assessment.started_at | formatDate }}</span>
                </div>
            </b-col>
        </b-row>

        <b-row class="justify-content-start" align-v="center" align-h="start" v-if="assessment">
            <b-col cols="1"> &nbsp;</b-col>
            <b-col cols="11">
                <strong>{{ immediacyText }}</strong> {{ assessment.relevant_symptom }}
            </b-col>
        </b-row>

        <b-row v-if="assessment.severity_direction">
            <b-col cols="1"></b-col>
            <b-col cols="11">
                Since this occurred is has gotten: {{ assessment.severity_direction }}
            </b-col>
        </b-row>

        <b-row v-if="assessment.last_treatment">
            <b-col cols="1"></b-col>
            <b-col cols="11">
                Last Treatment: {{ assessment.last_treatment }}
            </b-col>
        </b-row>

        <b-row v-if="assessment.negative_factors">
            <b-col cols="1"></b-col>
            <b-col cols="11">
                Negative Factors: {{ assessment.negative_factors }}
            </b-col>
        </b-row>

        <b-row v-if="assessment.postive_factors">
            <b-col cols="1"></b-col>
            <b-col cols="11">
                Positive Factors: {{ assessment.postive_factors }}
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import moment from "moment";

export default {
    props: {
        assessment: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isActive() {

            return this.assessment.active === 1 ? 'Active' : 'Resolved'
        },
        assessmentName() {
            return this.assessment?.sign_and_symptom ?? 'Not Found'
        },
        immediacyText() {
            return this.assessment.is_immediate === 1 ? "Is Immediate" : "Is Not Immediate"
        },
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            let momentObject = moment(value);

            return momentObject.format('MMMM Do YYYY, h:mm:ss a');
        },
    }
}
</script>